import { useSession } from 'next-auth/react';

export type AdminAction =
  | 'company.edit' // Access to Company Edit Page
  | 'company.togglePayable' // Access to Change the payable status of a company
  | 'user.edit' // Access to User Edit Page
  | 'user.create' // Access to User Create Action
  | 'user.lock' // Access to User Lock/Unlock Action
  | 'order.refund' // Access to Order Refund Action
  | 'companyVerificationRequest.edit' // Access to Company Verification Requests Edit Page
  | 'opportunity.archive' // Access to Opportunity Archive Action
  | 'opportunity.reassignPrimaryTeamMember' // Access to Opportunity Reassign Primary Team Member
  | 'featureFlag.edit'
  | 'featureFlag.create'
  | 'featureFlag.delete'
  | 'admin.paymentReports' // Access to Payment Reports
  | 'platform.edit'; // Access to edit platforms

export type AdminRole = 'SUPERADMIN' | 'ADMIN' | 'VIEWER';

export const ADMIN_ROLE_ACTIONS: Record<AdminRole, AdminAction[]> = {
  SUPERADMIN: [
    'company.edit',
    'company.togglePayable',
    'user.create',
    'user.edit',
    'user.lock',
    'companyVerificationRequest.edit',
    'opportunity.archive',
    'opportunity.reassignPrimaryTeamMember',
    'order.refund',
    'admin.paymentReports',
    'platform.edit',
    'featureFlag.edit',
    'featureFlag.create',
    'featureFlag.delete',
  ],
  ADMIN: [
    'company.edit',
    'user.lock',
    'companyVerificationRequest.edit',
    'opportunity.archive',
    'opportunity.reassignPrimaryTeamMember',
    'order.refund',
    'platform.edit',
    'featureFlag.edit',
    'featureFlag.create',
    'featureFlag.delete',
  ],
  VIEWER: [],
};

export const useAdminPermission = (action: AdminAction | AdminAction[]) => {
  const session = useSession();
  const role = session?.data?.user.adminRole;

  if (!role) return false;

  if (Array.isArray(action)) {
    return (
      action &&
      action.some((action) => ADMIN_ROLE_ACTIONS[role].includes(action))
    );
  }

  return ADMIN_ROLE_ACTIONS[role].includes(action);
};
