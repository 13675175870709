import * as React from 'react';
import '../styles/globals.css';
import { NextPage } from 'next';
import { SessionProvider } from 'next-auth/react';
import { AppProps } from 'next/app';
import { MainLayout } from '../components/layout/main';
import { ApolloProvider, client } from '@/components/services/apollo/client';
import { AdminAction } from '@theme/hooks/use-admin-permissions';

export type PageComponent = NextPage & {
  layout: 'auth' | 'dashboard';
  requiredAdminActionsToView?: AdminAction[];
};

const MyApp = (props: AppProps) => {
  const { pageProps } = props;
  const Component = props.Component as PageComponent;

  return (
    <>
      <SessionProvider>
        <ApolloProvider client={client}>
          {Component.layout === 'dashboard' && (
            <MainLayout
              requiredAdminActionsToView={Component.requiredAdminActionsToView}
            >
              <Component {...pageProps} />
            </MainLayout>
          )}

          {Component.layout === undefined && <Component {...pageProps} />}
        </ApolloProvider>
      </SessionProvider>
    </>
  );
};

export default MyApp;
