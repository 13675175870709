import * as React from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Spinner, SpinnerSize } from '@blueprintjs/core';

const GET_USER = gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      firstName
      lastName
      email
    }
  }
`;

interface LoggedInUserProps {
  sessionId: string;
  status: string;
}

export const LoggedInUser: React.FC<LoggedInUserProps> = (props) => {
  const { sessionId, status } = props;

  const [getUser, { loading, data }] = useLazyQuery(GET_USER);

  React.useEffect(() => {
    if (status === 'authenticated' && sessionId) {
      getUser({
        variables: { id: sessionId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  if (loading) {
    return <Spinner size={SpinnerSize.SMALL} />;
  }

  return (
    <div className="flex flex-col md:flex-row justify-between">
      {status === 'authenticated' && (
        <>
          {data?.getUser?.firstName} {data?.getUser?.lastName} -{' '}
          <strong>{data?.getUser?.email}</strong>
        </>
      )}
    </div>
  );
};
