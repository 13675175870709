/* eslint-disable */
import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client';
// import { refreshSession, hasSessionExpired } from '../auth/auth'
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/browser';
import { getSession } from 'next-auth/react';
import { signOut } from 'next-auth/react';

// import { getSession } from 'next-auth/client'

// const authMiddleware = setContext((operation, { headers }) =>
//   resolvePromise().then(data => ({
//     ...headers,
//     authorization: data.tokenOrSomething || null
//   }))
// );

interface ApiRequestHeaders {
  authorization?: string;
  'x-site-slug': string;
}

const httpLink = new HttpLink({
  uri: `/api/graphql`,
});

export async function refreshAuthenticationHeaders() {
  // try {
  //     const session = await refreshSession()
  //     return { headers: { authorization: `Bearer ${session.accessToken}` } }
  // } catch (e) {
  //     return { headers: {} }
  // }

  // await getSession()

  return { headers: {} };
}

export async function authenticationHeaders() {
  const headers: ApiRequestHeaders = {
    'x-site-slug': process.env.NEXT_PUBLIC_APP_SITE,
  };
  const session = await getSession();
  // const expired = hasSessionExpired(session)

  // if (session && session.isLoggedIn && !expired) {
  headers.authorization = `Bearer ${session.accessToken}`;
  return { headers };
  //}

  return refreshAuthenticationHeaders();
}

const signoutAndRedirectUser = async () => {
  await signOut({ redirect: false });
  window.location.href = '/';
};

const errorLink = onError(
  ({ graphQLErrors, networkError, response, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(
        ({
          message,
          extensions,
        }: {
          message: string;
          extensions: {
            [key: string]: any;
          };
        }) => {
          let signoutUser = false;

          /*
           * The graphql error will return a 500 status code but the extension will have a custom message of `user.account.disabled` as a 403 inside of extensions.response.body.errors object.
           * That is the error that we want to key off of for triggering the redirect.
           */
          extensions?.response?.body?.errors.forEach((error) => {
            if (
              error.message === 'user.account.disabled' &&
              error.extensions.code === 403
            ) {
              signoutUser = true;
            }
          });

          if (
            extensions.code !== 'FORBIDDEN' &&
            extensions.code !== 'UNAUTHENTICATED'
          ) {
            Sentry.captureException(
              new Error(`[GraphQL ${extensions.code}]: Message: ${message}`)
            );
            signoutUser = true;
          }

          if (signoutUser) {
            signoutAndRedirectUser();
          }
        }
      );
    }

    if (networkError) {
      Sentry.captureException(
        new Error(`[GraphQL Network error]: ${networkError}`)
      );
    }
  }
);

const authMiddleware: any = setContext((request, headers) =>
  authenticationHeaders()
);

export const client = new ApolloClient({
  ssrMode: false,
  link: from([errorLink, authMiddleware.concat(httpLink)]),
  cache: new InMemoryCache({}),
});

export { ApolloProvider } from '@apollo/client';

export default client;
