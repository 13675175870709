import { AdminAction } from '@theme/hooks/use-admin-permissions';

export const DASHBOARD_PAGES: Array<{
  slug: string;
  name: string;
  action: AdminAction;
}> = [
  {
    slug: 'companies',
    name: 'Companies',
    action: null,
  },
  {
    slug: 'users',
    name: 'Users',
    action: null,
  },
  {
    slug: 'company-verifications',
    name: 'Company Verifications',
    action: null,
  },
  {
    slug: 'opportunities',
    name: 'Opportunities',
    action: null,
  },
  {
    slug: 'orders',
    name: 'Orders',
    action: null,
  },
  {
    slug: 'platforms',
    name: 'Platforms',
    action: null,
  },
  {
    slug: 'payment-reports',
    name: 'Payment Reports',
    action: 'admin.paymentReports',
  },
  {
    slug: 'feature-flags',
    name: 'Feature Flags',
    action: null,
  },
];

export const UTILITIES_PAGES = [
  {
    slug: 'transactional-emails',
    name: 'Test Transactional Emails',
    action: null,
  },
];
