import * as React from 'react';
import { PropsWithChildren } from 'react';
import { Button } from '@blueprintjs/core';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

export type RequireSessionProps = PropsWithChildren<{
  redirect?: boolean;
  redirectPath?: string;
}>;

export const RequireSession: React.FC<RequireSessionProps> = (props) => {
  const router = useRouter();
  const { redirect, redirectPath, children } = props;

  const { data: session, status } = useSession();
  const sessionLoading = status === 'loading';

  React.useEffect(() => {
    if (!session && !sessionLoading) {
      if (redirect === true) {
        router.push(redirectPath || '/');
      }
    }
  }, [session, sessionLoading, redirectPath, redirect, router]);

  if (sessionLoading) return null;
  if (!session) {
    return (
      <div className="bp4-callout flex flex-col items-center justify-center h-screen w-screen">
        <p className="bp4-text-large mb-4">
          Session Required - Please return to login to sign in
        </p>
        <Button
          onClick={() => {
            router.push('/');
          }}
        >
          Return to Login
        </Button>
      </div>
    );
  }

  return <>{children}</>;
};
