import * as React from 'react';
import { PropsWithChildren } from 'react';
import {
  Alignment,
  AnchorButton,
  Button,
  ButtonGroup,
  Classes,
  Navbar,
  NavbarDivider,
  NavbarGroup,
  NavbarHeading,
  Position,
} from '@blueprintjs/core';

import { useRouter } from 'next/router';
import { useSession, signOut } from 'next-auth/react';
import Link from 'next/link';
import { Popover2 } from '@blueprintjs/popover2';
import { RequireSession } from '../auth/require-session';
import { AdminActionRedirect } from '../auth/admin-action-redirect';
import { LoggedInUser } from './logged-in-user';
import { AdminRestrictedLink } from '@/components/auth/admin-restricted-link';
import { AdminAction } from '@theme/hooks/use-admin-permissions';
import { DASHBOARD_PAGES, UTILITIES_PAGES } from '@theme/data/routes';

type MainLayoutProps = PropsWithChildren<{
  requiredAdminActionsToView?: AdminAction[];
  fullScreen?: boolean;
}>;

export const MainLayout: React.FC<MainLayoutProps> = (props) => {
  const { children, requiredAdminActionsToView } = props;
  const router = useRouter();
  const { data: session, status } = useSession();

  const logout = React.useCallback(async () => {
    await signOut({ redirect: false });
    router.push('/');
  }, [router]);

  React.useEffect(() => {
    if (status === 'unauthenticated') {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <RequireSession>
      <AdminActionRedirect actions={requiredAdminActionsToView}>
        <div>
          <Navbar>
            <NavbarGroup align={Alignment.LEFT}>
              <NavbarHeading>Vintro Admin</NavbarHeading>
              <NavbarDivider />
              <Link href="/dashboard" passHref>
                <AnchorButton
                  className={Classes.MINIMAL}
                  icon="home"
                  text="Home"
                />
              </Link>
              <Popover2
                position={Position.BOTTOM}
                interactionKind="hover"
                content={
                  <ButtonGroup minimal vertical alignText="left">
                    {DASHBOARD_PAGES.map((page) => {
                      return page.action ? (
                        <AdminRestrictedLink
                          key={page.slug}
                          href={`/dashboard/${page.slug}`}
                          action={page.action}
                          type="hidden"
                        >
                          <AnchorButton>{page.name}</AnchorButton>
                        </AdminRestrictedLink>
                      ) : (
                        <Link key={page.slug} href={`/dashboard/${page.slug}`}>
                          <AnchorButton>{page.name}</AnchorButton>
                        </Link>
                      );
                    })}
                  </ButtonGroup>
                }
              >
                <Button
                  className={Classes.MINIMAL}
                  icon="document"
                  text="Manage"
                />
              </Popover2>

              <Popover2
                position={Position.BOTTOM}
                interactionKind="hover"
                content={
                  <ButtonGroup minimal vertical alignText="left">
                    {UTILITIES_PAGES.map((page) => {
                      return page.action ? (
                        <AdminRestrictedLink
                          key={page.slug}
                          href={`/utilities/${page.slug}`}
                          action={page.action}
                          type="hidden"
                        >
                          <AnchorButton>{page.name}</AnchorButton>
                        </AdminRestrictedLink>
                      ) : (
                        <Link key={page.slug} href={`/utilities/${page.slug}`}>
                          <AnchorButton>{page.name}</AnchorButton>
                        </Link>
                      );
                    })}
                  </ButtonGroup>
                }
              >
                <Button
                  className={Classes.MINIMAL}
                  icon="wrench"
                  text="Utilities"
                />
              </Popover2>
            </NavbarGroup>
            <NavbarGroup
              align={Alignment.RIGHT}
              className="flex justify-around items-center gap-4"
            >
              <LoggedInUser sessionId={session?.user?.id} status={status} />
              <Button className={Classes.MINIMAL} onClick={logout}>
                Logout
              </Button>
            </NavbarGroup>
          </Navbar>
          <div className="main-layout-container p-8 overflow-y-auto">
            {children}
          </div>
        </div>
      </AdminActionRedirect>
    </RequireSession>
  );
};
