import { PropsWithChildren } from 'react';
import Link, { LinkProps } from 'next/link';
import { AnchorButton } from '@blueprintjs/core';
import {
  AdminAction,
  useAdminPermission,
} from '@theme/hooks/use-admin-permissions';

type AuthenticatedLinkProps = PropsWithChildren<
  LinkProps & {
    action: AdminAction;
    type?: 'hidden' | 'disabled';
  }
>;

export const AdminRestrictedLink: React.FC<AuthenticatedLinkProps> = (
  props
) => {
  const { type = 'disabled', action, children, ...linkProps } = props;
  const isAllowed = useAdminPermission(action);

  if (!isAllowed) {
    switch (type) {
      case 'disabled':
        return <AnchorButton disabled>{children}</AnchorButton>;
      case 'hidden':
        return null;
      default:
        return null;
    }
  }

  return (
    <Link {...linkProps} passHref>
      {children}
    </Link>
  );
};
