import { PropsWithChildren } from 'react';
import { useRouter } from 'next/router';
import {
  AdminAction,
  useAdminPermission,
} from '@theme/hooks/use-admin-permissions';

export type NonAdminRedirectProps = PropsWithChildren<{
  actions?: AdminAction[];
}>;

export const AdminActionRedirect: React.FC<NonAdminRedirectProps> = ({
  children,
  actions,
}) => {
  const isAllowed = useAdminPermission(actions);
  const router = useRouter();

  if (actions && !isAllowed) router.replace('/dashboard');

  return <>{children}</>;
};
